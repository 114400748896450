import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';

import { Switch } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import { useJsApiLoader } from '@react-google-maps/api';
import clsx from 'clsx';
import { endOfDay } from 'date-fns';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useEditManualSupplierMutation } from '../../../api/company';
import { ContactType, DeliveryDay } from '../../../api/company/types';
import { useGetMySupplierById } from '../../../api/supplier/hooks';
import SvgUploadPhoto from '../../../assets/icons/UploadPhoto';
import Logo from '../../../assets/images/logo/Logo_White.svg';
import { BlurredImage } from '../../../shared/components/blurred-image';
import { BusinessPlaceAutocomplete } from '../../../shared/components/business-place-autocomplete';
import { ThemedButton } from '../../../shared/components/themed-button';
import { EditIcon } from '../../../shared/components/edit-button';
import { FormikInput } from '../../../shared/components/formik-input';
import { ImageUpload } from '../../../shared/components/image-upload';
import { OverlayModal } from '../../../shared/components/overlay-modal';
import { CustomTimePicker } from '../../../shared/components/time-picker';
import { week } from '../../../shared/constants/week';
import { useScreenSize } from '../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../shared/services/toastService';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getIsImpersonated } from '../../../store/user';
import { backToAdminThunk } from '../../../store/user/user.actions';

import { useRippleStyles } from '../../../styles/customRipple';
import { useStyles } from './style';
import { useCurrentTheme } from '../../../api/admin-themes/hooks';

interface Values {
  business: string;
  ordersEmail?: string;
  phone?: string;
  freeDeliveryAt?: string;
  deliveryFee?: string;
}

const EditManualSupplier: React.FC = () => {
  const classes = useStyles();
  const rippleClass = useRippleStyles();
  const { isMobile } = useScreenSize();
  const { goBack } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { state: navState } = useLocation<{ setup: boolean } | null>();
  const supplierId = parseFloat(id) as number;
  const dispatch = useAppDispatch();
  const { logoUrl } = useCurrentTheme();

  const supplier = useGetMySupplierById(supplierId, true);
  const { email, contactId, officeId, phone } = useMemo(() => {
    const supplierContact = supplier?.company_contacts?.find((el) => el.is_order_email);
    const supplierOfficeContact = supplier?.company_contacts?.find((el) => el.contact_type === ContactType.OFFICE);
    return {
      email: supplierContact?.email || '',
      contactId: supplierContact?.id,
      phone: supplierOfficeContact?.phone_number || '',
      officeId: supplierOfficeContact?.id,
    };
  }, [supplier]);

  const [isOrderEmail, setIsOrderEmail] = useState(true);
  const [isImageUploadShown, setIsImageUploadShown] = useState(false);
  const [logo, setLogo] = useState<Blob | null>(null);
  const [selectedDays, setSelectedDays] = useState<DeliveryDay[]>([
    DeliveryDay.MONDAY,
    DeliveryDay.TUESDAY,
    DeliveryDay.WEDNESDAY,
    DeliveryDay.THURSDAY,
    DeliveryDay.FRIDAY,
  ]);
  const [time, setTime] = useState<Date>(endOfDay(new Date()));

  const [editManual, { isLoading: editLoading }] = useEditManualSupplierMutation();
  const impersonated = useAppSelector(getIsImpersonated);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries: ['places'],
  });

  const businessSchema = Yup.object().shape({
    business: Yup.string().required('Required field'),
    ordersEmail: Yup.string().email('Please enter a valid email'),
  });

  const onBackToAdmin = () => {
    dispatch(backToAdminThunk());
  };

  const onChangeTime = (value: Date) => {
    setTime(value);
  };

  const onSetDeliveryDay = (day: DeliveryDay) => {
    if (selectedDays.includes(day)) {
      setSelectedDays((state) => state.filter((item) => item !== day));
    } else {
      setSelectedDays((state) => [...state, day]);
    }
  };

  const onSubmit = async (values: Values, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(false);
    if (!supplierId) {
      return;
    }
    const reqBody = new FormData();
    reqBody.append('supplier_id', supplierId.toString());
    logo && reqBody.append('company[picture]', logo);
    reqBody.append('company[name]', values.business);
    reqBody.append(`company[contacts_attributes][0][contact_type]`, ContactType.ORDER);
    contactId && reqBody.append(`company[contacts_attributes][0][id]`, contactId.toString());
    reqBody.append(`company[contacts_attributes][0][email]`, isOrderEmail ? values.ordersEmail || '' : '');
    reqBody.append('company[contacts_attributes][0][is_order_email]', 'true');
    reqBody.append(`company[contacts_attributes][1][contact_type]`, ContactType.OFFICE);
    officeId && reqBody.append(`company[contacts_attributes][1][id]`, officeId.toString());
    reqBody.append(`company[contacts_attributes][1][phone_number]`, values.phone as string);
    supplier?.delivery_detail.id && reqBody.append('company[delivery_details_attributes][0][id]', supplier.delivery_detail.id.toString());
    values.deliveryFee !== undefined &&
      reqBody.append('company[delivery_details_attributes][0][delivery_fee_cents]', (+values.deliveryFee * 100).toString());
    time && reqBody.append('company[delivery_details_attributes][0][cutoff]', new Date(time).toISOString());
    values.freeDeliveryAt !== undefined &&
      reqBody.append('company[delivery_details_attributes][0][amount_for_free_delivery_cents]', (+values.freeDeliveryAt * 100).toString());
    week.forEach((day) => {
      reqBody.append(`company[delivery_details_attributes][0][${day.key}]`, selectedDays.includes(day.key).toString());
    });
    await editManual(reqBody);
    ToastService.success(`${values.business} has been updated`);
    goBack();
  };

  const onUploadLogoClick = () => {
    setIsImageUploadShown(true);
  };

  const onClearImage = () => {
    setIsImageUploadShown(false);
    setLogo(null);
  };

  useEffect(() => {
    setSelectedDays(Object.values(DeliveryDay).filter((day) => supplier?.delivery_detail[day]));
    supplier?.delivery_detail.cutoff && setTime(new Date(supplier?.delivery_detail.cutoff));
  }, [supplier?.delivery_detail]);

  return (
    <div className={classes.container}>
      <Formik
        initialValues={{
          business: supplier?.company.name || '',
          ordersEmail: email,
          phone,
          deliveryFee: supplier?.delivery_detail.delivery_fee_cents ? (supplier?.delivery_detail.delivery_fee_cents / 100).toString() : '0',
          freeDeliveryAt: supplier?.delivery_detail.amount_for_free_delivery_cents
            ? (supplier?.delivery_detail.amount_for_free_delivery_cents / 100).toString()
            : '0',
        }}
        onSubmit={onSubmit}
        validationSchema={businessSchema}
        enableReinitialize={true}
      >
        {({ submitForm, setFieldValue, values }) => (
          <Form className={classes.root}>
            <div className={classes.logoBox}>
              <img src={logoUrl || Logo} alt='OpenPantry' className={classes.logo} style={{ objectFit: 'contain', maxHeight: 70 }} />
            </div>
            <h2 className={classes.title}>{navState?.setup ? 'Complete Supplier setup' : 'Edit Supplier'}</h2>
            <div className={classes.businessRow}>
              {isLoaded && (
                <div className={classes.fieldWrap}>
                  <BusinessPlaceAutocomplete
                    placeholder='Search for business...'
                    setPlace={(place: google.maps.places.PlaceResult) => {
                      setFieldValue('business', place.name || '');
                    }}
                    label='Business Name*'
                    initialValue={values.business}
                    error={!values.business}
                  />
                </div>
              )}
              <div className={clsx([classes.imgWrap, rippleClass.ripple])} onClick={onUploadLogoClick}>
                {supplier?.company?.picture_url || logo ? (
                  <>
                    <div className={classes.addPhotoBg}>
                      <EditIcon />
                    </div>
                    <BlurredImage src={logo ? URL.createObjectURL(logo) : supplier?.company?.picture_url || ''} />
                  </>
                ) : (
                  <div className={classes.uploadPhoto}>
                    <SvgUploadPhoto />
                    {isMobile ? 'Upload' : 'Upload logo'}
                  </div>
                )}
              </div>
            </div>
            <div className={classes.fieldWrap}>
              <Field name='phone'>
                {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='Contact Phone Number' placeholder='Phone Number' />}
              </Field>
            </div>
            <div className={classes.fieldWrap}>
              <div className={classes.orderEmailWrap}>
                <span>SEND orders as a purchase order email?</span>
                <Switch
                  checked={isOrderEmail}
                  onChange={() => setIsOrderEmail(!isOrderEmail)}
                  color='primary'
                  classes={{
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                  }}
                />
              </div>
              {isOrderEmail && (
                <Field name='ordersEmail'>
                  {(fieldProps: FieldProps) => <FormikInput {...fieldProps} placeholder='Email address' autoComplete='on' />}
                </Field>
              )}
              <div className={classes.ordersEmailNote}>
                {isOrderEmail ? 'Orders will be sent as a purchase order to this email address' : 'No orders will be sent'}
              </div>
            </div>
            <div className={classes.deliveryBox}>
              <div className={classes.deliveryTitle}>Delivery Information</div>
              <div className={classes.deliverySubtitle}>Select the days this supplier delivers</div>
              <div className={classes.weekDaysBox}>
                {week.map(({ title, key }) => (
                  <div
                    key={key}
                    className={clsx({
                      [classes.weekDayItem]: true,
                      [classes.weekDayItemSelected]: selectedDays.includes(key),
                    })}
                    onClick={onSetDeliveryDay.bind(null, key)}
                  >
                    <CheckIcon className={classes.checkIcon} />
                    {title}
                  </div>
                ))}
              </div>
              <div className={classes.deliveryDetailsRow}>
                <div>
                  <div className={classes.cutOffLabel}>Order cut off time</div>
                  <CustomTimePicker time={time || endOfDay(new Date())} onSetTime={onChangeTime} />
                </div>
                <div>
                  <Field name='deliveryFee'>
                    {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='Delivery Fee' placeholder='0$' type='number' />}
                  </Field>
                </div>
                <div>
                  <Field name='freeDeliveryAt'>
                    {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='Free Delivery at' placeholder='0$' type='number' />}
                  </Field>
                </div>
              </div>
            </div>
            <div className={classes.btnsBlock}>
              <ThemedButton onClick={goBack} title={'Back'} buttonStyle='secondary' width={215} disabled={editLoading} />
              <ThemedButton onClick={submitForm} title={'Save'} width={215} disabled={!values.business || editLoading} />
            </div>
          </Form>
        )}
      </Formik>
      {impersonated && (
        <ThemedButton
          onClick={onBackToAdmin}
          title='Admin View'
          buttonStyle='greenTransparent'
          isSmall={true}
          customClass={classes.backToAdmin}
          width={140}
        />
      )}
      <OverlayModal isOpen={isImageUploadShown} onClose={setIsImageUploadShown.bind(null, false)}>
        <>
          <div className={classes.uploadTitle}>Upload Supplier logo</div>
          <div className={classes.uploadBox}>
            <ImageUpload
              saveImage={(picture) => {
                setLogo(picture);
                setIsImageUploadShown(false);
              }}
              closeModal={onClearImage}
              skipCloseOnSave={true}
              tipElement={
                <ThemedButton
                  customClass={classes.cancelUpload}
                  onClick={onClearImage}
                  title={'Cancel'}
                  width={150}
                  buttonStyle='secondary'
                />
              }
            />
          </div>
        </>
      </OverlayModal>
    </div>
  );
};

export default EditManualSupplier;
